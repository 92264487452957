<template>
  <v-app id="adminpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">

      <!--<router-link v-if="isAdmin" to="/nomencladores/user">Ver User</router-link>-->

      <router-view />
  </v-app>

</template>

<script>
import axios from 'axios';
//import { getUrlAPI } from 'axios-api.js';
export default {
  name: 'App',

  components: {

  },

  beforeCreate(){
    this.$store.commit("initializeStore")
    const access = this.$store.state.access
    if (access){
      axios.defaults.headers.common['Authorization'] = "JWT " + access
    }
    else {
      axios.defaults.headers.common['Authorization'] = ''
    }
    const token = this.$store.state.token;
    if (token){
      axios.defaults.headers.common['Authorization'] = "Token " + token
    }
    else{
      axios.defaults.headers.common['Authorization'] = ''
    }
  },
  mounted(){
      /*setInterval(() => {
        this.getAccess()
      }, 5000);*/
  },

  computed: {
    /*isAdmin() {
      return this.$route.matched.some(record => record.meta.requiresAdmin)
    },*/
    isAuthenticated() {
      // Aquí deberías implementar la lógica para verificar si el usuario está autenticado o no.
      // Devuelve `true` si está autenticado, `false` en caso contrario.
      return this.$store.state.isAuthenticated;
    },
    userRole() {
      // Aquí deberías implementar la lógica para obtener el rol del usuario actual.
      // Devuelve un string con el nombre del rol.
      return this.$store.state.permissions;
    },

  },

  watch: {
    '$route'(to) {
      // Verificamos que la ruta requiera autenticación y si el usuario no está autenticado,
      // lo redirigimos a la página de inicio de sesión.
      if (to.matched.some(record => record.meta.requiresAuth) && !this.isAuthenticated) {
        this.$router.push({ path: "/authentication/boxedlogin" });
      }

      // Verificamos que la ruta requiera un rol específico y si el usuario no tiene ese rol,
      // lo redirigimos a una página de error.
      //const requiredRoles = to.meta.roles;
      //if (requiredRoles && requiredRoles.length > 0 && !requiredRoles.includes(this.userRole)) {
      //  this.$router.push({ path: "/authentication/erroraccess" });
      //}
    },
  },

  methods: {
    getAccess(){
      /*
      const accessData = {
        refresh: this.$store.state.refresh
      }
      const getUrlAPI = 'http://localhost:8000';
      const url = getUrlAPI + '/api/auth/jwt/refresh/';

      axios.post(url, accessData)
        .then(response => {
            console.log(response);
            const access = response.data.access;
            localStorage.setItem("access", access);
            this.$store.commit('setAccess', access);

            this.$router.push({ path: "/dashboards/minimal" });
        })
        .catch(error => {
            console.log(error);
        })
      */
    }
  },


};
</script>
