import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
  //mode: "history",
  mode: "hash",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "dashboards/home",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        // Application
        /*{
          name: "Analytical",
          path: "dashboards/analytical",
          component: () => import("@/views/dashboards/analytical/Analytical"),
        },
        {
          name: "Modern",
          path: "dashboards/modern",
          component: () => import("@/views/dashboards/modern/Modern"),
        },
        {
          name: "Demographical",
          path: "dashboards/demographical",
          component: () =>
            import("@/views/dashboards/demographical/Demographical"),
        },*/
        {
          name: "Minimal",
          path: "dashboards/minimal",
          component: () => import("@/views/dashboards/minimal/Minimal"),
        },
        {
          name: "Home",
          path: "dashboards/home",
          component: () => import("@/views/dashboards/minimal/Home"),
        },
        {
          name: "Graficas",
          path: "dashboards/graficas",
          component: () => import("@/views/dashboards/minimal/Graficas"),
        },
        {
          name: "GraficasManif",
          path: "dashboards/graficasmanif",
          component: () => import("@/views/dashboards/minimal/GraficasManif"),
        },
        /*{
          name: "FESelects",
          path: "form-elements/FESelects",
          component: () => import("@/views/form-elements/FESelects"),
        },*/
      ],
    },

    {
      path: "/authentication",
      //redirect: "dashboards/minimal",
      //redirect: "authentication/boxedlogin",
      //component: () => import("@/layouts/full-layout/Layout"),
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
          {
            name: "BoxedLogin",
            path: "boxedlogin",
            component: () => import("@/views/authentication/BoxedLogin"),
          },
          {
            name: "FullLogin",
            path: "fulllogin",
            component: () => import("@/views/authentication/FullLogin"),
          },
          {
            name: "BoxedRegister",
            path: "boxedregister",
            component: () => import("@/views/authentication/BoxedRegister"),
          },
          {
            name: "FullRegister",
            path: "fullregister",
            component: () => import("@/views/authentication/FullRegister"),
          },
          {
            name: "Error",
            path: "error",
            component: () => import("@/views/authentication/Error"),
          },
          {
            name: "ErrorAccess",
            path: "erroraccess",
            component: () => import("@/views/authentication/ErrorAccess"),
          },
          {
            name: "ResetPassword",
            path: "resetpassword",
            component: () => import("@/views/authentication/ResetPassword"),
          },
          {
            name: "ChangePassword",
            path: "changepassword",
            component: () => import("@/views/authentication/ChangePassword"),
          },

        ],
    },

    {
      path: "/nomencladores",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
          //Nuevo
          {
            name: "User",
            path: "user",
            component: () => import("@/views/nomencladores/User"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "Rol",
            path: "rol",
            component: () => import("@/views/nomencladores/Rol"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "ConfigNotificacion",
            path: "confignotificacion",
            component: () => import("@/views/nomencladores/ConfigNotificacion"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "PreFactura",
            path: "prefactura",
            component: () => import("@/views/nomencladores/PreFactura"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "Factura",
            path: "factura",
            component: () => import("@/views/nomencladores/Factura"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "FacturaShow",
            path: "facturashow",
            component: () => import("@/views/nomencladores/manifiestodata/FacturaShow"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP', 'CL'] },
          },
          {
            name: "FacturaTimbradoShow",
            path: "facturatimbradoshow",
            component: () => import("@/views/nomencladores/manifiestodata/FacturaTimbradoShow"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP', 'CL'] },
          },
          {
            name: "PreFacturaShow",
            path: "prefacturashow",
            component: () => import("@/views/nomencladores/manifiestodata/PreFacturaShow"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP', 'CL'] },
          },
          {
            name: "TipoServicio",
            path: "tiposervicio",
            component: () => import("@/views/nomencladores/TipoServicio"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "Facturag",
            path: "facturag",
            component: () => import("@/views/nomencladores/Facturag"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "Facturap",
            path: "facturap",
            component: () => import("@/views/nomencladores/Facturap"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "Facturanp",
            path: "facturanp",
            component: () => import("@/views/nomencladores/Facturanp"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "Facturapre",
            path: "facturapre",
            component: () => import("@/views/nomencladores/Facturapre"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          /*{
            name: "Servicio",
            path: "servicio",
            component: () => import("@/views/nomencladores/Servicio"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },*/
          /*{
            name: "Funcionalidad",
            path: "funcionalidad",
            component: () => import("@/views/nomencladores/Funcionalidad"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },*/
          {
            name: "FLFormBasic",
            path: "FLFormBasic",
            component: () => import("@/views/nomencladores/userprofile/FLFormBasic"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP', 'CL'] },
          },
          {
            name: "TipoEmpleado",
            path: "tipoempleado",
            component: () => import("@/views/nomencladores/TipoEmpleado"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "Empleados",
            path: "empleado",
            component: () => import("@/views/nomencladores/Empleado"),
            meta: { requiresAuth: true , roles: ['ADMIN'] },
          },
          {
            name: "ClasificacionResiduos",
            path: "clasificacionresiduos",
            component: () => import("@/views/nomencladores/ClasificacionResiduos"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP'] },
          },
          {
            name: "Mezcla",
            path: "mezcla",
            component: () => import("@/views/nomencladores/Mezcla"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP'] },
          },
          {
            name: "TipoAlmacenaje",
            path: "tipoalmacenaje",
            component: () => import("@/views/nomencladores/TipoAlmacenaje"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'CL'] },
          },
          {
            name: "Ruta",
            path: "ruta",
            component: () => import("@/views/nomencladores/Ruta"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'CL'] },
          },
          {
            name: "RangoManifiesto",
            path: "rangomanifiesto",
            component: () => import("@/views/nomencladores/RangoManifiesto"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'CL'] },
          },
          {
            name: "Manifiesto",
            path: "manifiesto",
            component: () => import("@/views/nomencladores/Manifiesto"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'CL'] },
          },
          {
            name: "ManifiestoForm",
            path: "manifiestoform",
            component: () => import("@/views/nomencladores/manifiestodata/ManifiestoForm"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP', 'CL'] },
          },
          {
            name: "ManifiestoShow",
            path: "manifiestoshow",
            component: () => import("@/views/nomencladores/manifiestodata/ManifiestoShow"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP', 'CL'] },
          },
          {
            name: "ResiduosManejoEspecial",
            path: "residuosmanejoespecial",
            component: () => import("@/views/nomencladores/ResiduosManejoEspecial"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP', 'CL'] },
          },
          {
            name: "NoManifiesto",
            path: "nomanifiesto",
            component: () => import("@/views/nomencladores/NoManifiesto"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'CL'] },
          },
          {
            name: "UnidadTransporte",
            path: "unidadtransporte",
            component: () => import("@/views/nomencladores/UnidadTransporte"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'CL'] },
          },
          {
            name: "Traza",
            path: "traza",
            component: () => import("@/views/nomencladores/Traza"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'CL'] },
          },
          {
            name: "ManifiestoBorrador",
            path: "manifiestoborrador",
            component: () => import("@/views/nomencladores/ManifiestoBorrador"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'CL'] },
          },
          {
            name: "RecepcionExterna",
            path: "recepcionexterna",
            component: () => import("@/views/nomencladores/RecepcionExterna"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'CL'] },
          },
          {
            name: "RecepcionExternaForm",
            path: "recepcionexternaform",
            component: () => import("@/views/nomencladores/manifiestodata/RecepcionExternaForm"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP', 'CL'] },
          },
          {
            name: "RecepcionExternaShow",
            path: "recepcionexternashow",
            component: () => import("@/views/nomencladores/manifiestodata/RecepcionExternaShow"),
            meta: { requiresAuth: true , roles: ['ADMIN', 'OP', 'CL'] },
          },

      ],
    },

    {
      path: "/reportes",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "RepManifiesto",
          path: "repmanifiesto",
          component: () => import("@/views/reportes/RepManifiesto"),
          meta: { requiresAuth: true , roles: ['ADMIN', 'OP', 'CL'] },
        },
      ],
    },



    {
      path: "/empresa",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        // Otros
        {
          name: "Sucursal",
          path: "sucursal",
          component: () => import("@/views/empresa/Sucursal"),
          meta: { requiresAuth: true , roles: ['ADMIN'] },
        },
        {
          name: "SucursalTransportista",
          path: "sucursaltransportista",
          component: () => import("@/views/empresa/SucursalTransportista"),
          meta: { requiresAuth: true , roles: ['ADMIN'] },
        },
        {
          name: "SucursalReceptora",
          path: "sucursalreceptora",
          component: () => import("@/views/empresa/SucursalReceptora"),
          meta: { requiresAuth: true , roles: ['ADMIN'] },
        },
        {
          name: "Empresa",
          path: "empresa",
          component: () => import("@/views/empresa/Empresa"),
          meta: { requiresAuth: true , roles: ['ADMIN'] },
        },
        {
          name: "EmpresaTransportista",
          path: "empresatransportista",
          component: () => import("@/views/empresa/EmpresaTransportista"),
          meta: { requiresAuth: true , roles: ['ADMIN'] },
        },
        {
          name: "EmpresaReceptora",
          path: "empresareceptora",
          component: () => import("@/views/empresa/EmpresaReceptora"),
          meta: { requiresAuth: true , roles: ['ADMIN'] },
        },
      ],
    },

    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});
export default router;
