import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import axios from 'axios';
import VueToast from 'vue-toasted';
//import VueAxios from 'vue-axios';


//Vue.use(VueAxios, axios);
Vue.use(VueToast);
Vue.use(VueFeather);
Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
//Vue.config.globalProperties.$axios = axios
//window.axios = axios

//axios.defaults.baseUrl = 'http://localhost:8000';
axios.defaults.baseUrl = 'https://ecowaste-ws.idooproject.com';


new Vue({
  vuetify,
  store,
  router,
  axios,
  render: (h) => h(App),
}).$mount("#app");

console.log('Revisar');
console.log('Token: '+ store.state.token);
//console.log(store);
//console.log(router);
//console.log(router.history.current.fullPath);
//console.log(router.history.current.path);
//&& !router.history._startLocation.includes("/authentication/")
//if (store.state.token == '' && router.history.current.path == "/" && !router.history.current.path.includes("/authentication/")){
if (store.state.token == ''){
  //console.log(router.history._startLocation);
  router.push({ path: "/authentication/boxedlogin" });
  console.log('Corriendo');
}
